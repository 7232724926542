import './style.scss';
import { Media } from '../../../js/utils/const';
import UiModal from '../../../js/components/UiModal/UiModal';

export default !function () {
  const block = document.querySelector('.comments');

  if (!block) {
    return;
  }

  const reviews = block.querySelectorAll('.ui-text-review');
  const commentModal = block.querySelector('#reviews-modal')
    .content.querySelector('.ui-text-reviews-modal__content');
  const modalHeader = commentModal.querySelector('.ui-text-reviews-modal__header');
  const modalContent = commentModal.querySelector('.ui-text-reviews-modal__text');

  const getInitials = (name) => name.slice(0, 1);

  const showFullComment = (review) => {
    const header = review.querySelector('.ui-text-review-header');
    const content = review.querySelector('.ui-text-review__text');
    const avatar = header.querySelector('.ui-text-review-header__avatar-wrapper--initials');

    modalHeader.innerHTML = header.innerHTML;
    modalContent.innerHTML = content.innerHTML;

    // Задает фон для аватара
    if (avatar) {
      const modalAvatar = modalHeader.querySelector('.ui-text-review-header__avatar-wrapper--initials');
      modalAvatar.style.backgroundColor = getComputedStyle(avatar).backgroundColor;
    }

    new UiModal(commentModal, { wrapperClass: 'ui-text-reviews-modal__wrapper' }).init();
  };

  reviews.forEach((review) => {
    const avatar = review.querySelector('.ui-text-review-header__avatar-wrapper');
    const textElement = review.querySelector('.ui-text-review__text');
    const buttonShowMore = review.querySelector('.ui-text-review__show-more');

    // Задает инициалы для аватарки
    if (avatar.tagName.toLowerCase() === 'div') {
      const name = review.querySelector('.comments__name');
      avatar.textContent = getInitials(name.textContent.trim());
    }

    // Скрывает лишний текст
    if ((window.innerWidth <= Media.MD_MAX && textElement.offsetHeight > 355) || (window.innerWidth > Media.MD_MAX && textElement.offsetHeight > 250)) {
      textElement.classList.add('ui-text-review__text--hidden');
    } else {
      buttonShowMore.remove();
    }
  });

  block.addEventListener('click', async (evt) => {
    const target = evt.target;
    const videoButton = target.closest('[data-video]');
    const socialLink = target.closest('[data-social]');
    const buttonShowMore = target.closest('.ui-text-review__show-more');
    const buttonShowComments = target.closest('.comments__button');

    if (videoButton) {
      window.$analytics && window.$analytics.push('review-button-click', {
        blockName: block.dataset.blockName ? block.dataset.blockName : 'Блок без названия'
      });
    }

    if (socialLink) {
      window.$analytics && window.$analytics.push('review-social-click', {
        socialName: socialLink.dataset.social
      });
    }

    if (buttonShowMore) {
      const review = buttonShowMore.closest('.ui-text-review');
      review && showFullComment(review);
    }

    if (buttonShowComments && !buttonShowComments.disabled) {
      buttonShowComments.disabled = true
      const { CommentsModal } = await import('./CommentsModal/CommentsModal');
      new CommentsModal(showFullComment).init()
      buttonShowComments.disabled = false

      window.$analytics?.push('comments-show-all-comments-button-click')
    }
  });
}();
